/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import a from "../BarChartMediaInfo.js";
import r from "../ColumnChartMediaInfo.js";
import o from "../ImageMediaInfo.js";
import e from "../LineChartMediaInfo.js";
import i from "../PieChartMediaInfo.js";
import t from "../mixins/MediaInfo.js";
const m = {
  base: t,
  key: "type",
  defaultKeyValue: "image",
  typeMap: {
    "bar-chart": a,
    "column-chart": r,
    "line-chart": e,
    "pie-chart": i,
    image: o
  }
};
export { m as types };