/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import r from "../../../Color.js";
import { JSONSupport as e } from "../../../core/JSONSupport.js";
import { clone as t } from "../../../core/lang.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import { Integer as i } from "../../../core/accessorSupport/ensureType.js";
import { subclass as p } from "../../../core/accessorSupport/decorators/subclass.js";
import l from "./ChartMediaInfoValueSeries.js";
var n;
let c = n = class extends e {
  constructor(o) {
    super(o), this.colors = null, this.fields = [], this.normalizeField = null, this.series = [], this.tooltipField = null;
  }
  clone() {
    return new n({
      colors: t(this.colors),
      fields: t(this.fields),
      normalizeField: this.normalizeField,
      series: t(this.series),
      tooltipField: this.tooltipField
    });
  }
};
o([s({
  type: [r],
  json: {
    type: [[i]],
    write: !0
  }
})], c.prototype, "colors", void 0), o([s({
  type: [String],
  json: {
    write: !0
  }
})], c.prototype, "fields", void 0), o([s({
  type: String,
  json: {
    write: !0
  }
})], c.prototype, "normalizeField", void 0), o([s({
  type: [l],
  json: {
    read: !1
  }
})], c.prototype, "series", void 0), o([s({
  type: String,
  json: {
    write: !0
  }
})], c.prototype, "tooltipField", void 0), c = n = o([p("esri.popup.content.support.ChartMediaInfoValue")], c);
const d = c;
export { d as default };