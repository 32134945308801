/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { ClonableMixin as t } from "../../core/Clonable.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as r } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import e from "./Content.js";
import p from "../support/UtilityNetworkAssociationType.js";
let c = class extends t(e) {
  constructor(o) {
    super(o), this.description = null, this.title = null, this.associationTypes = [], this.type = "utility-network-associations";
  }
};
o([s({
  type: String,
  json: {
    write: !0
  }
})], c.prototype, "description", void 0), o([s({
  type: String,
  json: {
    write: !0
  }
})], c.prototype, "title", void 0), o([s({
  type: [p],
  json: {
    write: !0
  }
})], c.prototype, "associationTypes", void 0), o([r({
  utilityNetworkAssociations: "utility-network-associations"
})], c.prototype, "type", void 0), c = o([i("esri.popup.content.UtilityNetworkAssociationsContent")], c);
const n = c;
export { n as default };