/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { ClonableMixin as r } from "../../core/Clonable.js";
import { JSONSupport as t } from "../../core/JSONSupport.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import { Integer as s } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { enumeration as p } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as a } from "../../core/accessorSupport/decorators/subclass.js";
import { dateFormatJSONMap as c } from "../../intl/date.js";
let i = class extends r(t) {
  constructor(o) {
    super(o), this.dateFormat = null, this.digitSeparator = !1, this.places = null;
  }
};
o([p(c)], i.prototype, "dateFormat", void 0), o([e({
  type: Boolean,
  json: {
    write: !0
  }
})], i.prototype, "digitSeparator", void 0), o([e({
  type: s,
  json: {
    write: !0
  }
})], i.prototype, "places", void 0), i = o([a("esri.popup.support.FieldInfoFormat")], i);
const m = i;
export { m as default };