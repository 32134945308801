/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import { property as r } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as t } from "../../../core/accessorSupport/decorators/subclass.js";
import e from "./MediaInfo.js";
import s from "../support/ChartMediaInfoValue.js";
let p = class extends e {
  constructor(o) {
    super(o), this.type = null, this.value = void 0;
  }
};
o([r({
  type: ["bar-chart", "column-chart", "line-chart", "pie-chart"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], p.prototype, "type", void 0), o([r({
  type: s,
  json: {
    write: !0
  }
})], p.prototype, "value", void 0), p = o([t("esri.popup.content.mixins.ChartMediaInfo")], p);
const a = p;
export { a as default };