/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { property as r } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as e } from "../../../core/accessorSupport/decorators/subclass.js";
var s;
let l = s = class extends t {
  constructor(o) {
    super(o), this.color = null, this.fieldName = null, this.tooltip = null, this.value = null;
  }
  clone() {
    return new s({
      color: this.color?.clone(),
      fieldName: this.fieldName,
      tooltip: this.tooltip,
      value: this.value
    });
  }
};
o([r()], l.prototype, "color", void 0), o([r()], l.prototype, "fieldName", void 0), o([r()], l.prototype, "tooltip", void 0), o([r()], l.prototype, "value", void 0), l = s = o([e("esri.popup.content.support.ChartMediaInfoValueSeries")], l);
const p = l;
export { p as default };