/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import { JSONSupport as t } from "../core/JSONSupport.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as o } from "../core/accessorSupport/decorators/subclass.js";
var s;
let p = s = class extends t {
  constructor(r) {
    super(r), this.name = null, this.title = null, this.expression = null, this.returnType = null;
  }
  clone() {
    return new s({
      name: this.name,
      title: this.title,
      expression: this.expression,
      returnType: this.returnType
    });
  }
};
r([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "name", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "title", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "expression", void 0), r([e({
  type: ["string", "number"],
  json: {
    write: !0
  }
})], p.prototype, "returnType", void 0), p = s = r([o("esri.popup.ExpressionInfo")], p);
const i = p;
export { i as default };