/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import { JSONSupport as o } from "../core/JSONSupport.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as e } from "../core/accessorSupport/decorators/subclass.js";
var s;
let p = s = class extends o {
  constructor(r) {
    super(r), this.title = null, this.expression = null, this.returnType = "dictionary";
  }
  clone() {
    return new s({
      title: this.title,
      expression: this.expression
    });
  }
};
r([t({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "title", void 0), r([t({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "expression", void 0), r([t({
  type: ["dictionary"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], p.prototype, "returnType", void 0), p = s = r([e("esri.popup.ElementExpressionInfo")], p);
const i = p;
export { i as default };