/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "./chunks/tslib.es6.js";
import { ClonableMixin as e } from "./core/Clonable.js";
import o from "./core/Collection.js";
import { JSONSupport as s } from "./core/JSONSupport.js";
import { clone as i } from "./core/lang.js";
import r from "./core/Logger.js";
import { isPromiseLike as n } from "./core/promiseUtils.js";
import { property as p } from "./core/accessorSupport/decorators/property.js";
import { cast as l } from "./core/accessorSupport/decorators/cast.js";
import { reader as a } from "./core/accessorSupport/decorators/reader.js";
import { subclass as d } from "./core/accessorSupport/decorators/subclass.js";
import { writer as c } from "./core/accessorSupport/decorators/writer.js";
import { ensureOneOfType as f } from "./core/accessorSupport/ensureType.js";
import { collectFields as m, collectArcadeFieldNames as u, extractSubstitutionTemplatesFromString as h } from "./layers/support/fieldUtils.js";
import { persistableWebSceneTypes as y, persistableTypes as I } from "./popup/content.js";
import F from "./popup/ExpressionInfo.js";
import _ from "./popup/FieldInfo.js";
import w from "./popup/LayerOptions.js";
import x from "./popup/RelatedRecordsInfo.js";
import { types as g } from "./popup/content/support/mediaInfoTypes.js";
import A from "./support/actions/ActionBase.js";
import N from "./support/actions/ActionButton.js";
import C from "./support/actions/ActionToggle.js";
import j from "./popup/content/MediaContent.js";
import S from "./popup/content/TextContent.js";
import E from "./popup/content/AttachmentsContent.js";
import O from "./popup/content/FieldsContent.js";
import R from "./popup/content/ExpressionContent.js";
import T from "./popup/content/RelationshipContent.js";
import v from "./popup/content/UtilityNetworkAssociationsContent.js";
import b from "./popup/content/Content.js";
import J from "./popup/content/CustomContent.js";
const B = "relationships/",
  L = "expression/",
  M = o.ofType({
    key: "type",
    defaultKeyValue: "button",
    base: A,
    typeMap: {
      button: N,
      toggle: C
    }
  }),
  k = {
    base: b,
    key: "type",
    typeMap: {
      media: j,
      custom: J,
      text: S,
      attachments: E,
      fields: O,
      expression: R,
      relationship: T,
      utilityNetworkAssociations: v
    }
  },
  P = new Set(["attachments", "fields", "media", "text", "expression", "relationship"]),
  W = new Set([...P, "utility-network-associations"]);
let U = class extends e(s) {
  constructor() {
    super(...arguments), this.actions = null, this.content = "", this.expressionInfos = null, this.fieldInfos = null, this.layerOptions = null, this.lastEditInfoEnabled = !0, this.outFields = null, this.overwriteActions = !1, this.returnGeometry = !1, this.title = "";
  }
  castContent(t) {
    return Array.isArray(t) ? t.map(t => f(k, t)) : "string" == typeof t || "function" == typeof t || t instanceof HTMLElement || n(t) ? t : (r.getLogger(this).error("content error", "unsupported content value", {
      value: t
    }), null);
  }
  readContent(t, e) {
    const {
      popupElements: o
    } = e;
    return Array.isArray(o) && o.length > 0 ? this._readPopupInfoElements(e.description, e.mediaInfos, o) : this._readPopupInfo(e);
  }
  writeWebSceneContent(t, e, o, s) {
    this._writePopupTemplateContent(t, e, s);
  }
  writeWebMapContent(t, e, o, s) {
    this._writePopupTemplateContent(t, e, s);
  }
  writeFieldInfos(t, e, o, s) {
    const {
        content: i
      } = this,
      r = Array.isArray(i) ? i : null;
    if (t) {
      const o = r ? r.filter(t => "fields" === t.type) : [],
        i = o.length && o.every(t => t.fieldInfos?.length);
      e.fieldInfos = t.filter(Boolean).map(t => {
        const e = t.toJSON(s);
        return i && (e.visible = !1), e;
      });
    }
    if (r) for (const n of r) "fields" === n.type && this._writeFieldsContent(n, e);
  }
  writeLayerOptions(t, e, o, s) {
    e[o] = !t || null === t.showNoDataRecords && null === t.returnTopmostRaster ? null : t.toJSON(s);
  }
  writeTitle(t, e) {
    e.title = t || "";
  }
  async collectRequiredFields(t, e) {
    const o = this.expressionInfos || [];
    await this._collectExpressionInfoFields(t, e, [...o, ...this._getContentExpressionInfos(this.content, o)]), m(t, e, [...(this.outFields || []), ...this._getActionsFields(this.actions), ...this._getTitleFields(this.title), ...this._getContentFields(this.content)]);
  }
  async getRequiredFields(t) {
    const e = new Set();
    return await this.collectRequiredFields(e, t), [...e].sort();
  }
  _writePopupTemplateContent(t, e, o) {
    "string" != typeof t ? Array.isArray(t) && (e.popupElements = t.filter(t => "web-scene" === o?.origin ? P.has(t.type) : W.has(t.type)).map(t => t?.toJSON(o)), e.popupElements.forEach(t => {
      "attachments" === t.type ? this._writeAttachmentContent(e) : "media" === t.type ? this._writeMediaContent(t, e) : "text" === t.type ? this._writeTextContent(t, e) : "relationship" === t.type && this._writeRelationshipContent(t, e);
    })) : e.description = t;
  }
  _writeFieldsContent(t, e) {
    if (!Array.isArray(t.fieldInfos) || !t.fieldInfos.length) return;
    const o = i(t.fieldInfos);
    Array.isArray(e.fieldInfos) ? o.forEach(t => {
      const o = e.fieldInfos.find(e => e.fieldName?.toLowerCase() === t.fieldName?.toLowerCase());
      o ? o.visible = !0 : e.fieldInfos.push(t);
    }) : e.fieldInfos = o;
  }
  _writeAttachmentContent(t) {
    t.showAttachments || (t.showAttachments = !0);
  }
  _writeRelationshipContent(t, e) {
    const o = t.orderByFields?.map(e => this._toFieldOrderJSON(e, t.relationshipId)) || [],
      s = [...(e.relatedRecordsInfo?.orderByFields || []), ...o];
    e.relatedRecordsInfo = {
      showRelatedRecords: !0,
      ...(s?.length && {
        orderByFields: s
      })
    };
  }
  _writeTextContent(t, e) {
    !e.description && t.text && (e.description = t.text);
  }
  _writeMediaContent(t, e) {
    if (!Array.isArray(t.mediaInfos) || !t.mediaInfos.length) return;
    const o = i(t.mediaInfos);
    Array.isArray(e.mediaInfos) ? e.mediaInfos = [...e.mediaInfos, ...o] : e.mediaInfos = o;
  }
  _readPopupInfoElements(t, e, o) {
    const s = {
      description: !1,
      mediaInfos: !1
    };
    return o.map(o => "media" === o.type ? (o.mediaInfos || !e || s.mediaInfos || (o.mediaInfos = e, s.mediaInfos = !0), j.fromJSON(o)) : "text" === o.type ? (o.text || !t || s.description || (o.text = t, s.description = !0), S.fromJSON(o)) : "attachments" === o.type ? E.fromJSON(o) : "fields" === o.type ? O.fromJSON(o) : "expression" === o.type ? R.fromJSON(o) : "relationship" === o.type ? T.fromJSON(o) : "utilityNetworkAssociations" === o.type ? v.fromJSON(o) : void 0).filter(Boolean);
  }
  _toRelationshipContent(t) {
    const {
      field: e,
      order: o
    } = t;
    if (!e?.startsWith(B)) return null;
    const s = e.replace(B, "").split("/");
    if (2 !== s.length) return null;
    const i = parseInt(s[0], 10),
      r = s[1];
    return "number" == typeof i && r ? T.fromJSON({
      relationshipId: i,
      orderByFields: [{
        field: r,
        order: o
      }]
    }) : null;
  }
  _toFieldOrderJSON(t, e) {
    const {
      order: o,
      field: s
    } = t;
    return {
      field: `${B}${e}/${s}`,
      order: o
    };
  }
  _readPopupInfo({
    description: t,
    mediaInfos: e,
    showAttachments: o,
    relatedRecordsInfo: s = {
      showRelatedRecords: !1
    }
  }) {
    const i = [];
    t ? i.push(new S({
      text: t
    })) : i.push(new O()), Array.isArray(e) && e.length && i.push(j.fromJSON({
      mediaInfos: e
    })), o && i.push(E.fromJSON({
      displayType: "auto"
    }));
    const {
      showRelatedRecords: r,
      orderByFields: n
    } = s;
    return r && n?.length && n.forEach(t => {
      const e = this._toRelationshipContent(t);
      e && i.push(e);
    }), i.length ? i : t;
  }
  _getContentElementFields(t) {
    const e = t?.type;
    if ("attachments" === e) return [...this._extractFieldNames(t.title), ...this._extractFieldNames(t.description)];
    if ("custom" === e) return t.outFields || [];
    if ("fields" === e) return [...this._extractFieldNames(t.title), ...this._extractFieldNames(t.description), ...this._getFieldInfoFields(t.fieldInfos ?? this.fieldInfos)];
    if ("media" === e) {
      const e = t.mediaInfos || [];
      return [...this._extractFieldNames(t.title), ...this._extractFieldNames(t.description), ...e.reduce((t, e) => [...t, ...this._getMediaInfoFields(e)], [])];
    }
    return "text" === e ? this._extractFieldNames(t.text) : "relationship" === e || "utility-network-associations" === e ? [...this._extractFieldNames(t.title), ...this._extractFieldNames(t.description)] : [];
  }
  _getMediaInfoFields(t) {
    const {
        caption: e,
        title: o,
        value: s
      } = t,
      i = s || {},
      {
        fields: r,
        normalizeField: n,
        tooltipField: p,
        sourceURL: l,
        linkURL: a
      } = i,
      d = [...this._extractFieldNames(o), ...this._extractFieldNames(e), ...this._extractFieldNames(l), ...this._extractFieldNames(a), ...(r ?? [])];
    return n && d.push(n), p && d.push(p), d;
  }
  _getContentExpressionInfos(t, e) {
    return Array.isArray(t) ? t.reduce((t, e) => [...t, ...("expression" === e.type && e.expressionInfo ? [e.expressionInfo] : [])], e) : [];
  }
  _getContentFields(t) {
    return "string" == typeof t ? this._extractFieldNames(t) : Array.isArray(t) ? t.reduce((t, e) => [...t, ...this._getContentElementFields(e)], []) : [];
  }
  async _collectExpressionInfoFields(t, e, o) {
    o && (await Promise.all(o.map(o => u(t, e, o.expression))));
  }
  _getFieldInfoFields(t) {
    return t ? t.filter(({
      fieldName: t,
      visible: e
    }) => !(void 0 !== e && !e || !t || t.startsWith(B) || t.startsWith(L))).map(t => t.fieldName) : [];
  }
  _getActionsFields(t) {
    return t ? t.toArray().reduce((t, e) => [...t, ...this._getActionFields(e)], []) : [];
  }
  _getActionFields(t) {
    const {
        className: e,
        title: o,
        type: s
      } = t,
      i = "button" === s || "toggle" === s ? t.image : "";
    return [...this._extractFieldNames(o), ...this._extractFieldNames(e), ...this._extractFieldNames(i)];
  }
  _getTitleFields(t) {
    return "string" == typeof t ? this._extractFieldNames(t) : [];
  }
  _extractFieldNames(t) {
    return h(t).filter(t => !(0 === t.indexOf(B) || 0 === t.indexOf(L)));
  }
};
t([p({
  type: M
})], U.prototype, "actions", void 0), t([p()], U.prototype, "content", void 0), t([l("content")], U.prototype, "castContent", null), t([a("content", ["description", "fieldInfos", "popupElements", "mediaInfos", "showAttachments", "relatedRecordsInfo"])], U.prototype, "readContent", null), t([c("web-scene", "content", {
  popupElements: {
    type: o.ofType(y)
  },
  showAttachments: {
    type: Boolean
  },
  mediaInfos: {
    type: o.ofType(g)
  },
  description: {
    type: String
  },
  relatedRecordsInfo: {
    type: x
  }
})], U.prototype, "writeWebSceneContent", null), t([c("content", {
  popupElements: {
    type: o.ofType(I)
  },
  showAttachments: {
    type: Boolean
  },
  mediaInfos: {
    type: o.ofType(g)
  },
  description: {
    type: String
  },
  relatedRecordsInfo: {
    type: x
  }
})], U.prototype, "writeWebMapContent", null), t([p({
  type: [F],
  json: {
    write: !0
  }
})], U.prototype, "expressionInfos", void 0), t([p({
  type: [_]
})], U.prototype, "fieldInfos", void 0), t([c("fieldInfos")], U.prototype, "writeFieldInfos", null), t([p({
  type: w
})], U.prototype, "layerOptions", void 0), t([c("layerOptions")], U.prototype, "writeLayerOptions", null), t([p({
  type: Boolean,
  json: {
    read: {
      source: "showLastEditInfo"
    },
    write: {
      target: "showLastEditInfo"
    },
    default: !0
  }
})], U.prototype, "lastEditInfoEnabled", void 0), t([p()], U.prototype, "outFields", void 0), t([p()], U.prototype, "overwriteActions", void 0), t([p()], U.prototype, "returnGeometry", void 0), t([p({
  json: {
    type: String
  }
})], U.prototype, "title", void 0), t([c("title")], U.prototype, "writeTitle", null), U = t([d("esri.PopupTemplate")], U);
const q = U;
export { q as default };