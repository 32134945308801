/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { ClonableMixin as o } from "../../core/Clonable.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import { Integer as r } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import p from "./Content.js";
import i from "../support/RelatedRecordsInfoFieldOrder.js";
let n = class extends o(p) {
  constructor(t) {
    super(t), this.description = null, this.displayCount = null, this.displayType = "list", this.orderByFields = null, this.relationshipId = null, this.title = null, this.type = "relationship";
  }
};
t([e({
  type: String,
  json: {
    write: !0
  }
})], n.prototype, "description", void 0), t([e({
  type: Number,
  json: {
    type: r,
    write: !0
  }
})], n.prototype, "displayCount", void 0), t([e({
  type: ["list"],
  json: {
    write: !0
  }
})], n.prototype, "displayType", void 0), t([e({
  type: [i],
  json: {
    write: !0
  }
})], n.prototype, "orderByFields", void 0), t([e({
  type: Number,
  json: {
    type: r,
    write: !0
  }
})], n.prototype, "relationshipId", void 0), t([e({
  type: String,
  json: {
    write: !0
  }
})], n.prototype, "title", void 0), t([e({
  type: ["relationship"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], n.prototype, "type", void 0), n = t([s("esri.popup.content.RelationshipContent")], n);
const l = n;
export { l as default };