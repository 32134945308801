/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { clone as o } from "../../core/lang.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { reader as r } from "../../core/accessorSupport/decorators/reader.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as s } from "../../core/accessorSupport/decorators/writer.js";
import a from "./BarChartMediaInfo.js";
import p from "./ColumnChartMediaInfo.js";
import n from "./Content.js";
import d from "./ImageMediaInfo.js";
import m from "./LineChartMediaInfo.js";
import c from "./PieChartMediaInfo.js";
import { types as f } from "./support/mediaInfoTypes.js";
var l;
let u = l = class extends n {
  constructor(t) {
    super(t), this.activeMediaInfoIndex = null, this.attributes = null, this.description = null, this.mediaInfos = null, this.title = null, this.type = "media";
  }
  readMediaInfos(t) {
    return t && t.map(t => "image" === t.type ? d.fromJSON(t) : "barchart" === t.type ? a.fromJSON(t) : "columnchart" === t.type ? p.fromJSON(t) : "linechart" === t.type ? m.fromJSON(t) : "piechart" === t.type ? c.fromJSON(t) : void 0).filter(Boolean);
  }
  writeMediaInfos(t, o) {
    o.mediaInfos = t && t.map(t => t.toJSON());
  }
  clone() {
    return new l(o({
      activeMediaInfoIndex: this.activeMediaInfoIndex,
      attributes: this.attributes,
      description: this.description,
      mediaInfos: this.mediaInfos,
      title: this.title
    }));
  }
};
t([e()], u.prototype, "activeMediaInfoIndex", void 0), t([e({
  type: Object,
  json: {
    write: !0
  }
})], u.prototype, "attributes", void 0), t([e({
  type: String,
  json: {
    write: !0
  }
})], u.prototype, "description", void 0), t([e({
  types: [f]
})], u.prototype, "mediaInfos", void 0), t([r("mediaInfos")], u.prototype, "readMediaInfos", null), t([s("mediaInfos")], u.prototype, "writeMediaInfos", null), t([e({
  type: String,
  json: {
    write: !0
  }
})], u.prototype, "title", void 0), t([e({
  type: ["media"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], u.prototype, "type", void 0), u = l = t([i("esri.popup.content.MediaContent")], u);
const I = u;
export { I as default };