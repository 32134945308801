/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "./content/AttachmentsContent.js";
import n from "./content/Content.js";
export { default as CustomContent } from "./content/CustomContent.js";
import o from "./content/ExpressionContent.js";
import e from "./content/FieldsContent.js";
import i from "./content/MediaContent.js";
import s from "./content/RelationshipContent.js";
import r from "./content/TextContent.js";
import m from "./content/UtilityNetworkAssociationsContent.js";
function p(t) {
  return t instanceof n;
}
const a = {
    base: null,
    key: "type",
    typeMap: {
      attachment: t,
      media: i,
      text: r,
      expression: o,
      field: e,
      relationship: s,
      utilityNetworkAssociations: m
    }
  },
  c = {
    base: null,
    key: "type",
    typeMap: {
      attachment: t,
      media: i,
      text: r,
      expression: o,
      field: e,
      relationship: s
    }
  };
export { t as AttachmentsContent, n as BaseContent, o as ExpressionContent, e as FieldsContent, i as MediaContent, s as RelationshipContent, r as TextContent, m as UtilityNetworkAssociationsContent, p as isContent, a as persistableTypes, c as persistableWebSceneTypes };