/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { ClonableMixin as o } from "../../core/Clonable.js";
import { JSONSupport as e } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import { Integer as r } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
let i = class extends o(e) {
  constructor(t) {
    super(t), this.title = null, this.description = null, this.type = null, this.open = !1, this.associatedNetworkSourceId = null, this.associatedAssetGroup = null, this.associatedAssetType = null;
  }
};
t([s({
  type: String,
  json: {
    write: !0
  }
})], i.prototype, "title", void 0), t([s({
  type: String,
  json: {
    write: !0
  }
})], i.prototype, "description", void 0), t([s({
  type: ["attachment", "connectivity", "container", "content", "structure"],
  json: {
    write: !0
  }
})], i.prototype, "type", void 0), t([s({
  type: Boolean,
  json: {
    read: !1
  }
})], i.prototype, "open", void 0), t([s({
  type: Number,
  json: {
    type: r,
    write: !0
  }
})], i.prototype, "associatedNetworkSourceId", void 0), t([s({
  type: Number,
  json: {
    type: r,
    write: !0
  }
})], i.prototype, "associatedAssetGroup", void 0), t([s({
  type: Number,
  json: {
    type: r,
    write: !0
  }
})], i.prototype, "associatedAssetType", void 0), i = t([p("esri.popup.support.UtilityNetworkAssociationType")], i);
const c = i;
export { c as default };