/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { clone as t } from "../../core/lang.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
import s from "./Content.js";
var p;
let i = p = class extends s {
  constructor(o) {
    super(o), this.creator = null, this.destroyer = null, this.outFields = null, this.type = "custom";
  }
  clone() {
    return new p({
      creator: this.creator,
      destroyer: this.destroyer,
      outFields: Array.isArray(this.outFields) ? t(this.outFields) : null
    });
  }
};
o([r()], i.prototype, "creator", void 0), o([r()], i.prototype, "destroyer", void 0), o([r()], i.prototype, "outFields", void 0), o([r({
  type: ["custom"],
  readOnly: !0
})], i.prototype, "type", void 0), i = p = o([e("esri.popup.content.CustomContent")], i);
const c = i;
export { c as default };